import { Screenshot } from "../../types/commontypes";

const IMAGE_DAYA_PLACE_HOLDER = '{{IMAGE_DATA}}'
const WIDTH_PLACE_HOLDER = '{{WIDTH}}'
const HEIGHT_PLACE_HOLDER = '{{HEIGHT}}'
const DRAWIO_TEMPLATE = `<?xml version="1.0" encoding="UTF-8"?><mxfile host="embed.diagrams.net" modified="" agent="" version="18.0.7" etag="" type="embed"><diagram id="" name="Page-1"><mxGraphModel dx="" dy="" grid="1" gridSize="10" guides="1" tooltips="1" connect="1" arrows="1" fold="1" page="1" pageScale="1" pageWidth="" pageHeight="" math="0" shadow="0"><root><mxCell id="0" /><mxCell id="1" parent="0" /><mxCell id="2" value="" style="shape=image;verticalLabelPosition=bottom;labelBackgroundColor=default;verticalAlign=top;aspect=fixed;imageAspect=0;image=${IMAGE_DAYA_PLACE_HOLDER};" vertex="1" parent="1"><mxGeometry x="0" y="0" width="${WIDTH_PLACE_HOLDER}" height="${HEIGHT_PLACE_HOLDER}" as="geometry" /></mxCell></root></mxGraphModel></diagram></mxfile>`;
const DRAW_DOMAIN = 'https://embed.diagrams.net/'
const FRAME_STYLE = 'position:absolute;border:0;width:100%;height:100%;';
const EXPORT_FORMAT = 'xmlpng';
const USE_LIBRARIES = true;

export class ScreenshotEditor {
    public uiTheme: string|null = 'min';
    public config = {
        maxImageSize: '10000',
        maxImageBytes: '30000000'
    }
    private frame: HTMLIFrameElement|null = null;
    private xml: string = '';
    private previousOverflow: string = document.body.style.overflow;

    constructor(public title: string, screenshot: Screenshot, public onExport: (exportedData: string) => void) {
        this.xml = DRAWIO_TEMPLATE.replace(IMAGE_DAYA_PLACE_HOLDER, screenshot.data.replace(';base64', ''))
            .replace(WIDTH_PLACE_HOLDER, screenshot.width.toString())
            .replace(HEIGHT_PLACE_HOLDER, screenshot.height.toString())
    }

    handleMessageEvent(event) {
        if (this.frame != null && 
            event.source === this.frame.contentWindow && 
            event.data.length > 0) {
            try {
                const msg = JSON.parse(event.data);
                if (msg != null){
                    this.handleMessage(msg);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    createFrame(url, style) {
        const frame = document.createElement('iframe');
        frame.setAttribute('frameborder', '0');
        frame.setAttribute('style', style);
        frame.setAttribute('src', url);
    
        return frame;
    }

    /**
     * Send the given message to the iframe.
     */
    postMessage(msg) {
        if (this.frame != null) {
            this.frame.contentWindow?.postMessage(JSON.stringify(msg), '*');
        }
    }

    /**
     * Returns the CSS style for the iframe.
     */
    getFrameStyle() {
        return FRAME_STYLE + ';left:' +
            document.body.scrollLeft + 'px;top:' +
            document.body.scrollTop + 'px;';
    }
    
    /**
     * Returns the URL for the iframe.
     */
    getFrameUrl() {
        let url = DRAW_DOMAIN + '?proto=json&spin=1';
    
        if (this.uiTheme != null) {
            url += '&ui=' + this.uiTheme;
        }
    
        if (USE_LIBRARIES != null) {
            url += '&libraries=1';
        }
    
        if (this.config != null) {
            url += '&configure=1';
        }
    
        // if (this.urlParams != null) {
        //     url += '&' + this.urlParams.join('&');
        // }
    
        return url;
    }

    /**
     * Sets the status of the editor.
     */
    setStatus(messageKey, modified) {
        this.postMessage({action: 'status', messageKey: messageKey, modified: modified});
    }
    
    /**
     * Updates the waiting cursor.
     */
    setWaiting(waiting) {
        if (this.frame != null) {
            if (waiting){
                this.frame.style.pointerEvents = 'none';
            } else {
                this.frame.style.pointerEvents = '';
            }
        }
    }

    /**
     * Updates the waiting cursor.
     */
    setActive(active) {
        if (active) {
            this.previousOverflow = document.body.style.overflow;
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = this.previousOverflow;
        }
    }

    /**
     * Posts configure message to editor.
     */
    configureEditor() {
        this.postMessage({action: 'configure', config: this.config});
    }

    /**
     * Posts load message to editor.
     */
    initializeEditor() {
        this.postMessage({action: 'load',autosave: 1, saveAndExit: '1',
            modified: 'unsavedChanges', xml: this.xml,
            title: this.title});
        this.setWaiting(false);
        this.setActive(true);
    }

    startEditing() {
        if (this.frame == null) {
            window.addEventListener('message', (event) => this.handleMessageEvent(event));
    
            this.frame = this.createFrame(
                this.getFrameUrl(),
                this.getFrameStyle());
            // TODO: allow appending to a container element
            document.body.appendChild(this.frame);
            this.setWaiting(true);
        }
    }

    /**
     * Removes the iframe.
     */
    stopEditing() {
        if (this.frame != null) {
            window.removeEventListener('message', this.handleMessageEvent);
            document.body.removeChild(this.frame);
            this.setActive(false);
            this.frame = null;
        }
        this.xml = "";
    }

    /**
     * Handles the given message.
     */
    handleMessage(msg: any) {
        if (msg.event === 'configure') {
            this.configureEditor();
        } else if (msg.event === 'init') {
            this.initializeEditor();
        } else if (msg.event === 'autosave') {
            this.xml = msg.xml
        } else if (msg.event === 'export') {
            this.onExport(msg.data)
            this.stopEditing();
        } else if (msg.event === 'save') {
            this.xml = msg.xml;
    
            if (msg.exit) {
                msg.event = 'exit';
            } else {
                this.setStatus('allChangesSaved', false);
            }
        } 
        if (msg.event === 'exit'){

            if (this.xml != null) {
                this.postMessage({
                    action: 'export', 
                    format: EXPORT_FORMAT,
                    xml: this.xml, 
                    spinKey: 'export'
                });
            } else {
                this.stopEditing();
            }
        }
    }
}
