import React, {useContext} from 'react'
import { Button } from 'react-bootstrap';
import { default as MapStoreContext, MapStore } from '../../stores/mapstore';
import html2canvas from 'html2canvas'
import { Screenshot } from '../../types/commontypes';
import { ScreenshotEditor } from '../../business/screenshot/screenshoteditor';
import { download } from '../../business/screenshot/download';

const edit = (screenshot: Screenshot|null) => {
    if (screenshot == null) {
        return
    }
    const editor = new ScreenshotEditor("", screenshot, download)
    editor.uiTheme = null
    editor.startEditing()
}

const screenshotMap = async (): Promise<Screenshot|null> => {
    const mapDom = document.getElementById('map')
    return mapDom == null 
        ? null
        : html2canvas(mapDom, {useCORS: true}).then((canvas: HTMLCanvasElement) => ({
            data: canvas.toDataURL('image/png'),
            height: canvas.height,
            width: canvas.width
        }));
}

export const ScreenshotButton = () => {
    const mapStore: MapStore = useContext(MapStoreContext);
    return <Button variant="secondary border"
        onClick={() => {
            mapStore.hideControl()
            setTimeout(async () => {
                const screenshot = await screenshotMap()
                edit(screenshot)
            }, 500)
            setTimeout(() => mapStore.showControl(), 1000)
        }}
    >
        <i className="bi bi-camera"></i>
    </Button>
}
