import { NominatimClient } from "../../client/nominatim";
import { OsmPath, SearchResult } from "../../types/commontypes";
import { SearchProvider } from "./provider";

export class OsmSearch implements SearchProvider {
    private nominatimClient = new NominatimClient()

    async search(queryInput: string): Promise<SearchResult[]|null> {
        const osmPlaces = await this.nominatimClient.search(queryInput)
        if (osmPlaces == null) {
            return null
        }
        const transformedResults = osmPlaces.map((osmPlace): (SearchResult|null) => {
            const latNe = Number(osmPlace.boundingbox[1])
            const latSw = Number(osmPlace.boundingbox[0])
            const lngNe = Number(osmPlace.boundingbox[3])
            const lngSw = Number(osmPlace.boundingbox[2])
            let result: SearchResult|null = null
            if (osmPlace.class === 'place') {
                result = {
                    id: `osmPoint:${osmPlace.place_id}`,
                    displayName: osmPlace.display_name,
                    boundingBox: {
                        northEast: {
                            lat: latNe,
                            lng: lngNe
                        },
                        southWest: {
                            lat: latSw,
                            lng: lngSw
                        }
                    },
                    center: {
                        lat: Number(osmPlace.lat),
                        lng: Number(osmPlace.lon)
                    },
                    class: 'point',                
                }
            } else if (osmPlace.class === 'boundary') {
                let osmPathGroup: OsmPath[]|null = null

                if (osmPlace.geojson.type === 'Polygon') {
                    osmPathGroup = [osmPlace.geojson.coordinates[0]]
                } else if (osmPlace.geojson.type === 'MultiPolygon') {
                    osmPathGroup = osmPlace.geojson.coordinates[0]
                }
                if (osmPathGroup != null) {
                    result = {
                        id: `osmBoundary:${osmPlace.osm_id}`,
                        displayName: osmPlace.display_name,
                        boundingBox: {
                            northEast: {
                                lat: latNe,
                                lng: lngNe
                            },
                            southWest: {
                                lat: latSw,
                                lng: lngSw
                            }
                        },
                        center: {
                            lat: Number(osmPlace.lat),
                            lng: Number(osmPlace.lon)
                        },
                        class: 'boundary',
                        paths: osmPathGroup.map(points => points.map(longlat => ({
                            lng: longlat[0],
                            lat: longlat[1]
                        })))
                        
                    }
                }
            }
            return result
        })
        const filteredResults: SearchResult[] = []
        transformedResults.forEach(r => {
            if (r != null) {
                filteredResults.push(r)
            }
        })
        return filteredResults;
    }
}
