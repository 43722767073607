import axios from "axios";
import { OsmPlace } from "../types/commontypes";

const BaseUri = "https://nominatim.openstreetmap.org/search.php?polygon_geojson=1&format=json"

class NominatimClient {

    async search(query: string): Promise<OsmPlace[]> {
        const uri = `${BaseUri}&q=${query}`
        const response = await axios.get(uri)
        return response.data as OsmPlace[]
    }
}

export {NominatimClient}
