// @ts-nocheck
// source: https://stackoverflow.com/a/55762772
HTMLCanvasElement.prototype.getContext = function(origFn) {
    return function(type, attribs) {
        attribs = attribs || {};
        if (type === 'webgl' || type === 'webgl2') {
            attribs.preserveDrawingBuffer = true;
        }
        return origFn.call(this, type, attribs);
    };
}(HTMLCanvasElement.prototype.getContext);
  
export {}
