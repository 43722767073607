import { makeAutoObservable, observable, action } from "mobx";
import { createContext } from "react"

class SidebarStore {
    @observable isSidebarVisible: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    @action showSideBar() {
        this.isSidebarVisible = true
    }

    @action hideSideBar() {
        this.isSidebarVisible = false
    }
}

export default createContext(new SidebarStore())
export {SidebarStore}
