import { SearchResult } from "../../types/commontypes";

type SearchProviderInfo = {
    key: string,
    longName: string,
    shortName: string
}

const searchProviders: {[key: string]: SearchProviderInfo} = {
    'osm': {
        key: 'osm',
        longName: "OpenStreetMap",
        shortName: "O"
    
    },
    'bing': {
        key: 'bing',
        longName: 'Bing Map',
        shortName: 'B'
    
    },
}
const searchProviderKeys = Object.keys(searchProviders)
export interface SearchProvider {
    search: (queryInput: string) => Promise<SearchResult[]|null>
}

export {searchProviders, searchProviderKeys}
export type {SearchProviderInfo}
