import {observer} from "mobx-react-lite"
import React, { useContext } from "react";
import { Button, FormControl, InputGroup, Row, Col, ListGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { searchProviders, searchProviderKeys, SearchProvider } from "../../../business/searchprovider/provider";
import { default as ProviderStoreContext, ProviderStore } from "../../../stores/providerstore";
import { default as SearchBoxStoreContext, SearchBoxStore }  from "../../../stores/searchboxstore"
import {SearchResultWidget} from "./searchresult"

const handleSearchInputChange = (evt, searchBoxStore: SearchBoxStore) => {
    searchBoxStore.changeSearchInput(evt.target.value)
}

const handleSearchClick = async (searchBoxStore: SearchBoxStore, providerStore: ProviderStore) => {
    const searchInput = searchBoxStore.searchInput
    let searchProvider: SearchProvider|null = null
    switch (searchBoxStore.searchProvider) {
        case 'osm':
            searchProvider = providerStore.osmSeaerch
            break;
        case 'bing':
            searchProvider = providerStore.bingmapSearch
            break;
    }
    if (searchProvider != null) {
        const searchResults = await searchProvider.search(searchInput)
        if (searchResults != null) {
            searchBoxStore.changeResults(searchResults)
        }
    }
}

const renderSearchResults = (searchBoxStore: SearchBoxStore) => {
    if (searchBoxStore.results == null || searchBoxStore.results.length === 0) {
        return null
    }
    const results: any = []
    for (const p of searchBoxStore.results) {
        results.push(<SearchResultWidget place={p} key={p.id}/>)
    }
    return <>
        <Row className="mb-3" key="results-title">
            <Col className="d-flex align-items-center justify-content-between">
                <b>Results</b>
                <Button variant="outline-secondary border" size="sm" onClick={() => searchBoxStore.clearResults()}>
                    <i className="bi bi-x"></i>
                </Button>
            </Col>
        </Row>
        <Row key="results">
            <Col>
                <ListGroup>{results}</ListGroup>
            </Col>
        </Row>
    </>
}

const renderSearchProviders = (searchBoxStore: SearchBoxStore) => {
    const searchProviderWidges: any[] = []

    searchProviderKeys.forEach((k, i) => {
        const provider = searchProviders[k]
        const dropdownOption = (<Dropdown.Item key={`provider-${i}`} onClick={() => searchBoxStore.changeSearchProvider(k)}>
            {provider.longName}
        </Dropdown.Item>)
        searchProviderWidges.push(dropdownOption)
    })
    return searchProviderWidges
}

const SearchBox = observer(() => {
    const searchBoxStore: SearchBoxStore = useContext(SearchBoxStoreContext);
    const providerStore: ProviderStore = useContext(ProviderStoreContext)

    return <>
        <Row>
            <Col>
                <InputGroup className="mb-3" >
                    <DropdownButton
                        variant="outline-secondary border-end-0 border"
                        title={searchProviders[searchBoxStore.searchProvider].shortName}
                        id="search-provider-picker"
                    >
                        {renderSearchProviders(searchBoxStore)}
                    </DropdownButton>
                    <FormControl
                        className="w-50 form-control border-end-0 border"
                        placeholder="Place of interest"
                        aria-label="Place of interest"
                        aria-describedby="basic-addon2"
                        onChange={(evt) => handleSearchInputChange(evt, searchBoxStore)}
                        onKeyPress={(evt) => {
                            if (evt.key === 'Enter') {
                                handleSearchClick(searchBoxStore, providerStore)
                            }
                        }}
                        value={searchBoxStore.searchInput}
                    />
                    <Button variant="outline-secondary border-start-0 border-end-0 border" 
                        id="button-addon2" 
                        type="reset"
                        onClick={() => searchBoxStore.changeSearchInput('')}
                    >
                        <i className="bi bi-x"></i>
                    </Button >
                    <Button variant="outline-secondary border-start-0 border" 
                        id="button-addon2"
                        type="submit"
                        onClick={() => handleSearchClick(searchBoxStore, providerStore)}
                    >
                        <i className="bi bi-search"></i>
                    </Button >
                </InputGroup>

            </Col>
        </Row>
        {renderSearchResults(searchBoxStore)}
    </>
})

export {SearchBox}
