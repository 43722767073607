export function convertFromSlider(input: string, min: number, max: number): number {
    const parsedInput = Number(input)
    if (!isNaN(parsedInput)) {
        // 0 to 100
        return min + (max - min) * (parsedInput / 100)
    }
    return Number.NaN
}

export function convertToSlider(value: number, min: number, max: number): string {
    let fromValue = value
    if (fromValue > max) {
        fromValue = max
    } else if (fromValue < min) {
        fromValue = min
    }
    return String((fromValue - min) / (max - min) * 100)
}
