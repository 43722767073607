/// <reference path="../../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts" />
import {useScript} from '../../hooks/usescript'
import React, {useContext, useEffect} from 'react'
import { default as ProviderStoreContext, ProviderStore } from '../../stores/providerstore';

const BingMap = () => {
    const bingSdkUri = 'https://www.bing.com/maps/sdkrelease/mapcontrol?callback=InitBingMapSdk'
    const providerStore: ProviderStore = useContext(ProviderStoreContext)

    useEffect(() => {
        (window as any).InitBingMapSdk = () => {
            try {
                const bingmap = new Microsoft.Maps.Map('#bingmap', {
                    credentials: process.env.REACT_APP_BMAP_KEY
                });
                providerStore.initializeBingmapSearch(bingmap)
            } catch(e) {
                console.error(e)
            }
        }
    })
    useScript(bingSdkUri)

    return <div id="bingmap" style={{height: 0, width: 0}}></div>
}

export {BingMap}
