import React, { useEffect, useRef, useContext } from "react";
import ReactDOM from 'react-dom';
import {observer} from "mobx-react-lite"
import { MapStore, default as MapStoreContext } from "../../stores/mapstore";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Point } from "../../types/commontypes";
import { TopLeftControl } from "../mapcontrol/topleft";

const apiKey = process.env.REACT_APP_GMAP_KEY ? process.env.REACT_APP_GMAP_KEY : ""
let controlDiv: HTMLDivElement|null = null

function showMapControls(mapObj: google.maps.Map) {
    mapObj.setOptions({
        mapTypeControl: true,
        scaleControl: true,
        fullscreenControl: true,
        zoomControl: true
    });
    mapObj.controls[google.maps.ControlPosition.TOP_LEFT].setAt(0, controlDiv)
}

function hideMapControls(mapObj: google.maps.Map) {
    mapObj.setOptions({
        mapTypeControl: false,
        scaleControl: false,
        fullscreenControl: false,
        zoomControl: false
    });
    mapObj.controls[google.maps.ControlPosition.TOP_LEFT].clear()

}

const MyMap = observer<any, any>(({center, zoom}) => {
    const mapStore: MapStore = useContext(MapStoreContext);

    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        if (mapStore.mapObj == null) {
            const mapObj = new window.google.maps.Map(ref.current, {
                center,
                zoom,
                mapId: '925b737b06491a1c',
                gestureHandling: 'auto',
                isFractionalZoomEnabled: true,
                styles: [
                    {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }],
                    },
                ],
                mapTypeControlOptions: {
                  style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                  mapTypeIds: ["roadmap", "terrain", "satellite"],
                  position: google.maps.ControlPosition.TOP_RIGHT,          
                },
                streetViewControl: false,
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_RIGHT
                }
            });
            controlDiv = document.createElement("div");
            controlDiv.id = 'openSidebarButton'
            ReactDOM.render(
                <React.StrictMode>
                    <TopLeftControl/>
                </React.StrictMode>,
                controlDiv
            );

            mapStore.setMap(mapObj)
        }
    });
    
    if (mapStore.mapObj != null) {
        const mapObj = mapStore.mapObj
        mapStore.polygons.forEach(polygonGroup => {
            polygonGroup.gmapPolygons.forEach(p => {
                p.setMap(mapObj)
                p.setVisible(polygonGroup.isVisible)
            })
        })
        mapStore.markers.forEach(m => {
            m.gmapMarker.setMap(mapObj)
            m.gmapMarker.setVisible(m.isVisible)
        })
        if (mapStore.isControlHidden) {
            hideMapControls(mapObj)
        } else {
            showMapControls(mapObj)
        }
    }

    return <div ref={ref} id="map" style={{height: '100vh', width : '100%'}}/>;
})

const GoogleMap = ({center, zoom}: {center: Point, zoom: number}) => <Wrapper version="beta" apiKey={apiKey} 
    render={(status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <p>Loading...</p>;
      case Status.FAILURE:
        return <p>Failed</p>;
      case Status.SUCCESS:
        return <MyMap center={center} zoom={zoom} />;
    }
}} />;

export {GoogleMap}
