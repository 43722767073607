import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { default as SidebarStoreContext, SidebarStore } from '../../stores/sidebarstore';

export const OpenSidebarButton = () => {
    const sidebarStore: SidebarStore = useContext(SidebarStoreContext);

    return <Button variant="secondary border"
        onClick={() => sidebarStore.showSideBar()}
    >
        <i className="bi bi-search"></i>
    </Button>
}
