import React, { useContext } from 'react'
import { observer } from "mobx-react-lite";
import { default as MapStoreContext, MapStore } from '../../stores/mapstore';
import { Col, Row } from 'react-bootstrap';

export const PolygonListTitle = observer(() => {
    const mapStore: MapStore = useContext(MapStoreContext);
    if (mapStore.polygons.size === 0) {
        return null
    }
    return <Row className="mb-2 mt-3">
        <Col className="d-flex align-items-center justify-content-between">
            <b>Boundaries</b>
        </Col>
    </Row>
})