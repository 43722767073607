import { makeAutoObservable, observable, action, computed } from "mobx";
import { convertFromSlider, convertToSlider } from "../utils/slider";
import { Point } from "./commontypes";

const MIN_OPACITY = 0.001
const MAX_OPACITY = 0.999
const MIN_STROKE_WEIGHT = 1
const MAX_STROKE_WEIGHT = 10
const MIN_SCALE = 1
const MAX_SCALE = 20

class SymbolIconConfig {
    @observable public fillColor: string = "#00FF00"
    @observable public fillOpacity: number = MAX_OPACITY
    @observable public scale: number = 5
    @observable public strokeColor: string = "#000000"
    @observable public strokeWeight: number = MIN_STROKE_WEIGHT
    @observable public path: google.maps.SymbolPath
    gmapMarker: google.maps.Marker|null

    constructor(path: google.maps.SymbolPath, gmapMarker: google.maps.Marker|null) {
        makeAutoObservable(this)
        this.path = path
        this.gmapMarker = gmapMarker
    }

    @computed get opacityRangeValue(): string {
        return convertToSlider(this.fillOpacity, MIN_OPACITY, MAX_OPACITY)
    }

    @computed get strokeWeightRangeValue(): string {
        return convertToSlider(this.strokeWeight, MIN_STROKE_WEIGHT, MAX_STROKE_WEIGHT)
    }

    @computed get scaleRangeValue(): string {
        return convertToSlider(this.scale, MIN_SCALE, MAX_SCALE)
    }

    @action changeFillColor(newColor: string) {
        this.fillColor = newColor
        this.refreshMarker()
    }

    @action changeFillOpacity(input: string) {
        const opacity = convertFromSlider(input, MIN_OPACITY, MAX_OPACITY)
        if (!isNaN(opacity)) {
            this.fillOpacity = opacity
            this.refreshMarker()
        }
    }

    @action changeStrokeColor(newColor: string) {
        this.strokeColor = newColor
        this.refreshMarker()
    }

    @action changeStrokeWeight(input: string) {
        const weight = convertFromSlider(input, MIN_STROKE_WEIGHT, MAX_STROKE_WEIGHT)
        if (!isNaN(weight)) {
            this.strokeWeight = weight
            this.refreshMarker()
        }
    }    

    @action changeScale(input: string) {
        const scale = convertFromSlider(input, MIN_SCALE, MAX_SCALE)
        if (!isNaN(scale)) {
            this.scale = scale
            this.refreshMarker()
        }
    }

    refreshMarker() {
        if (this.gmapMarker != null) {
            const newIcon: google.maps.Symbol = {
                path: this.path,
                fillColor: this.fillColor,
                fillOpacity: this.fillOpacity,
                strokeColor: this.strokeColor,
                strokeWeight: this.strokeWeight,
                scale: this.scale
            }
            this.gmapMarker.setIcon(newIcon)
        }
    }
}

class MapPlace {
    @observable public isVisible: boolean = true
    @observable public iconType: 'default'|'builtin-symbol' = 'default'
    @observable public iconConfig?: SymbolIconConfig|null

    gmapMarker: google.maps.Marker

    constructor(public id: string, public displayName: string, point: Point) {
        makeAutoObservable(this)
        this.gmapMarker = new google.maps.Marker({
            position: point,
            title: displayName
        })
    }

    @action changeIconType(iconType: string) {
        switch (iconType) {
            case 'default':
                this.iconType = 'default'
                this.gmapMarker.setIcon(null)
                break;
            case 'builtin-symbol':
                this.iconType = 'builtin-symbol'
                this.changeIconToSymbol(google.maps.SymbolPath.CIRCLE)
                break;
        }
    }

    changeIconToSymbol(symbolPath: google.maps.SymbolPath) {
        this.iconConfig = new SymbolIconConfig(symbolPath, this.gmapMarker)
        this.iconConfig.refreshMarker()
    }
}

export {MapPlace}
