import { useContext } from 'react'
import { ListGroup } from 'react-bootstrap'
import {observer} from "mobx-react-lite"
import { MapStore, default as MapStoreContext } from "../../../stores/mapstore";
import { SearchResult } from '../../../types/commontypes';

const renderSearchResultWedget = (mapStore: MapStore, place: SearchResult) => {
    const addToMap = (place.class === 'boundary') ?
        () => mapStore.addBoundaries(place) :
        () => mapStore.addMarker(place)
    const placeTypeIcon = (place.class === 'boundary') ?
        <i className="bi bi-map align-middle"></i>:
        <i className="bi bi-geo-alt"></i>

    return <div className="d-flex align-items-center justify-content-between">
        <div style={{paddingRight: "0.5rem"}}>
            {placeTypeIcon}
        </div>
        <div style={{width: "100%"}}>
            {place.displayName}
        </div>
        <div>
            <span style={{paddingLeft: "0.5rem"}}>
                <i className="bi bi-cursor"
                    onClick={() => mapStore.panToBoundingBox(place.boundingBox)}
                ></i>
            </span>
            <span style={{paddingLeft: "0.5rem"}}>
                <i className="bi bi-plus-square"
                    onClick={addToMap}
                ></i>
            </span>
        </div>
    </div>
}

const SearchResultWidget = observer<any, any>((props) => {
    const {place}: {place: SearchResult} = props
    const mapStore: MapStore = useContext(MapStoreContext);
    return <ListGroup.Item>
        {renderSearchResultWedget(mapStore, place)}
    </ListGroup.Item>
})

export {SearchResultWidget}
