/// <reference path="../../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts" />
import { SearchResult } from "../../types/commontypes";
import { SearchProvider } from "./provider";

export class BingMapSearch implements SearchProvider {
    constructor(private bingmap: Microsoft.Maps.Map, private searchManager: Microsoft.Maps.Search.SearchManager) {
    }

    async search(queryInput: string): Promise<SearchResult[]|null> {
        const that = this
        return new Promise((resolve, reject) => {
            const geocodeRequest: Microsoft.Maps.Search.IGeocodeRequestOptions = {
                bounds: new Microsoft.Maps.LocationRect(new Microsoft.Maps.Location(0, 0), 360, 180),
                where: queryInput,
                callback: ((geocodeResult) => {
                    that.getBoundary(geocodeResult, resolve)
                }),
                errorCallback: function (...e) {
                    //If there is an error, alert the user about it.
                    // alert("No results found.");
                    console.error(e)

                    resolve(null)
                }
            };
    
            //Make the geocode request.
            this.searchManager.geocode(geocodeRequest);
        })
    }

    getBoundary(geocodeResult: Microsoft.Maps.Search.IGeocodeResult, resolveSearch: Function){
        const that = this
        //Add the first result to the map and zoom into it.
        console.log(`geocodeResult: ${JSON.stringify(geocodeResult)}`)
        if (geocodeResult && geocodeResult.results && geocodeResult.results.length > 0) {
            const boundaryPromises: Promise<SearchResult>[] = geocodeResult.results.map(geocodeResult => new Promise<SearchResult>((resolve) => {
                let entityType: string|null = null
                switch (geocodeResult.entityType) {
                    case "CountryRegion":
                    case "AdminDivision1":
                    case "AdminDivision2":
                    case "Postcode1":
                    case "Postcode2":
                    case "Postcode3":
                    case "Postcode4":
                    case "Neighborhood":
                    case "PopulatedPlace":
                        entityType = geocodeResult.entityType
                        break;
                    default:
                }
                if (entityType != null) {
                    //Create the request options for the GeoData API.
                    const geoDataRequestOptions = {
                        lod: 1,
                        getAllPolygons: true,
                        entityType
                    };
                    //Use the GeoData API manager to get the boundaries of the zip codes.
                    Microsoft.Maps.SpatialDataService.GeoDataAPIManager.getBoundary(
                        geocodeResult.location,
                        geoDataRequestOptions,
                        that.bingmap,
                        function (data) {
                            //Add the polygons to the map.
                            if (data.results && data.results.length > 0) {
                                resolve(BingMapSearch.createBoundaryPlace(geocodeResult, data.results[0]))
                            } else {
                                //Display a pushpin if a boundary isn't found.
                                resolve(BingMapSearch.createPointPlace(geocodeResult))
                            }
                    });
                } else {
                    //Display a pushpin if GeoData API does not support EntityType.
                    resolve(BingMapSearch.createPointPlace(geocodeResult))
                }
            }))
            //Verify that the geocoded location has a supported entity type.

            Promise.all(boundaryPromises).then(boundaries => resolveSearch(boundaries))
        }
    }

    static createPointPlace(result: Microsoft.Maps.Search.IPlaceResult): SearchResult {
        const pointPlace: SearchResult = {
            id: `bingPointPlace:${result.location.latitude}-lng-${result.location.longitude}`,
            displayName: result.address.formattedAddress,
            class: 'point',
            center: {
                lat: result.location.latitude,
                lng: result.location.longitude
            },
            boundingBox: {
                northEast: {
                    lat: result.bestView.getNorth(),
                    lng: result.bestView.getEast(),
                },
                southWest: {
                    lat: result.bestView.getSouth(),
                    lng: result.bestView.getWest(),
                }
            }
        }
        return pointPlace
    }

    static createBoundaryPlace(
        geocodeResult: Microsoft.Maps.Search.IPlaceResult,
        geoDataResult: Microsoft.Maps.SpatialDataService.IGeoDataResult): SearchResult {
        const place: SearchResult = {
            id: `bingBoundaryPlace:lat-${geocodeResult.location.latitude}-lng-${geocodeResult.location.longitude}`,
            displayName: geocodeResult.address.formattedAddress,
            class: 'boundary',
            center: {
                lat: geocodeResult.location.latitude,
                lng: geocodeResult.location.longitude
            },
            boundingBox: {
                northEast: {
                    lat: geocodeResult.bestView.getNorth(),
                    lng: geocodeResult.bestView.getEast(),
                },
                southWest: {
                    lat: geocodeResult.bestView.getSouth(),
                    lng: geocodeResult.bestView.getWest(),
                }
            },
            paths: geoDataResult.Polygons.map(polygon => polygon.getRings()[0].map(location => ({
                lat: location.latitude,
                lng: location.longitude
            })))
        }
        return place
    }
}
