import React, { useContext } from "react";
import { Accordion, Button, Form, ButtonGroup, FormControl, InputGroup} from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { MapStore, default as MapStoreContext } from "../../stores/mapstore";
import { ShowHideIcon } from "../common/showhideicon";

const PolygonWidget = observer<any, any>(({id}: {id: string}) => {
    const mapStore: MapStore = useContext(MapStoreContext);
    const polygon = mapStore.polygons.get(id)
    if (polygon == null) {
        return null
    } 
    return <Accordion.Item eventKey={id}>
        <Accordion.Header>{polygon.displayName}</Accordion.Header>
        <Accordion.Body style={{paddingLeft: "0.5rem", paddingRight: "0.5rem"}}>
            <div className="d-flex align-items-top justify-content-between">
                <InputGroup style={{paddingLeft: 0, paddingRight: "0.5rem"}}>
                    <InputGroup.Text id="borderColorPicker">Line</InputGroup.Text>
                    <FormControl
                        type="color"
                        value={polygon.strokeColor}
                        title="Choose your color"
                        aria-label="Input group example"
                        aria-describedby="borderColorPicker"
                        onChange={(evt) => polygon.setStrokeColor(evt.target.value)}
                    />
                    <Form.Range 
                        value={polygon.strokeWeightRangeValue}
                        onChange={(evt) => polygon.setStrokeWeight(evt.target.value)}
                    />
                </InputGroup>
                <InputGroup style={{paddingLeft: 0, paddingRight: "0.5rem"}}>
                    <InputGroup.Text id="borderColorPicker">Fill</InputGroup.Text>
                    <FormControl
                        type="color"
                        value={polygon.fillColor}
                        title="Choose your color"
                        id="fillColorPicker"
                        onChange={(evt) => polygon.setFillColor(evt.target.value)}
                    />
                    <Form.Range 
                        value={polygon.opacityRangeValue}
                        onChange={(evt) => polygon.setFillOpacity(evt.target.value)}
                    />
                </InputGroup>
                <ButtonGroup>
                    <Button variant="outline-secondary border"
                        onClick={() => mapStore.togglePolygonVisible(id)}
                    >
                        <ShowHideIcon isVisible={polygon.isVisible}/>
                    </Button>
                    <Button variant="outline-secondary border"
                        onClick={() => mapStore.removePolygon(id)}
                    >
                        <i className="bi bi-x"></i>
                    </Button>
                </ButtonGroup>
            </div>
        </Accordion.Body>
    </Accordion.Item>
})

export {PolygonWidget}
