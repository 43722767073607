import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Accordion } from "react-bootstrap";
import { MapStore, default as MapStoreContext } from "../../stores/mapstore";
import { MarkerWidget } from "./markerwidget";

const MarkerList = observer(() => {
    const mapStore: MapStore = useContext(MapStoreContext);
    const widgets: any[] = []
    mapStore.markers.forEach((v, k) => {
        widgets.unshift(<MarkerWidget key={k} id={k}  />);

    })
    return <Accordion>
        {widgets}
    </Accordion>
})

export {MarkerList}