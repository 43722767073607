import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { OpenSidebarButton } from "./opensidebarbutton";
import { ScreenshotButton } from "./screenshotbutton";

export const TopLeftControl = () => {
    return <Container className="mt-3 ml-3">
        <div>
            <Row>
                <Col><OpenSidebarButton/></Col>
                <Col><ScreenshotButton/></Col>
            </Row>
        </div>
    </Container>
}
