import { action, computed, makeAutoObservable, observable, ObservableSet } from "mobx";
import { convertFromSlider, convertToSlider } from "../utils/slider";
import { Point } from "./commontypes";

const MIN_OPACITY = 0.001
const MAX_OPACITY = 0.999
const MIN_STROKE_WEIGHT = 1
const MAX_STROKE_WEIGHT = 10
const DEFAULT_OPACITY = 0.2
const DEFAULT_STROKE_COLOR = "#FF0000"
const DEFAULT_FILL_COLOR = "#FFFFFF"
const DEFAULT_STROKE_WEIGHT = 2

class PolygonGroup {
    @observable isVisible: boolean
    @observable fillColor: string = DEFAULT_FILL_COLOR
    @observable strokeColor: string = DEFAULT_STROKE_COLOR
    @observable strokeWeight: number = DEFAULT_STROKE_WEIGHT
    @observable fillOpacity: number = DEFAULT_OPACITY
    gmapPolygons: ObservableSet<google.maps.Polygon> = new ObservableSet<google.maps.Polygon>()

    constructor(public id: string, public displayName: string, public points: Point[][]){
        makeAutoObservable(this)
    
        this.isVisible = true
        points.forEach(polygonPoints => {
            this.gmapPolygons.add(new google.maps.Polygon({
                paths: polygonPoints,
                strokeColor: this.strokeColor,
                strokeOpacity: 0.8,
                strokeWeight: this.strokeWeight,
                fillColor: this.fillColor,
                fillOpacity: this.fillOpacity,
            }))
        })
    }

    @action setStrokeColor(newColor: string) {
        this.strokeColor = newColor
        this.gmapPolygons.forEach(p => p.setOptions({strokeColor: this.strokeColor}))
    }

    @action setStrokeWeight(input: string) {
        const weight = convertFromSlider(input, MIN_STROKE_WEIGHT, MAX_STROKE_WEIGHT)
        if (!isNaN(weight)) {
            this.strokeWeight = weight
            this.gmapPolygons.forEach(p => p.setOptions({strokeWeight: this.strokeWeight}))
        }
    }

    @action setFillColor(newColor: string) {
        this.fillColor = newColor
        this.gmapPolygons.forEach(p => p.setOptions({fillColor: this.fillColor}))
    }

    @action setFillOpacity(input: string) {
        const opacity = convertFromSlider(input, MIN_OPACITY, MAX_OPACITY)
        if (!isNaN(opacity)) {
            this.fillOpacity = opacity
            this.gmapPolygons.forEach(p => p.setOptions({fillOpacity: this.fillOpacity}))    
        }
    }

    @computed get strokeWeightRangeValue(): string {
        return convertToSlider(this.strokeWeight, MIN_STROKE_WEIGHT, MAX_STROKE_WEIGHT)
    }

    @computed get opacityRangeValue(): string {
        return convertToSlider(this.fillOpacity, MIN_OPACITY, MAX_OPACITY)
    }
}

export {PolygonGroup}
