import React, { useContext } from "react";
import { Accordion, Button, ButtonGroup, InputGroup, DropdownButton, Dropdown, FormControl, Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { MapStore, default as MapStoreContext } from "../../stores/mapstore";
import { ShowHideIcon } from "../common/showhideicon";
import { MapPlace } from "../../types/place";

const IconPickerTitleByType = {
    'default': 'Pin',
    'builtin-symbol': 'Circle'
}

const renderMarkerType = (marker: MapPlace) => {
    let scalePicker: any = null
    switch (marker.iconType) {
        case 'builtin-symbol':
            scalePicker = renderSymbolMarkerScalePicker(marker)
            break
    }
    const options = Object.keys(IconPickerTitleByType).map(k => {
        return <Dropdown.Item key={k}
            onClick={() => {
            marker.changeIconType(k)
        }}>{IconPickerTitleByType[k]}</Dropdown.Item>
    })
    return <InputGroup style={{paddingLeft: 0, paddingRight: "0.5rem"}}>
        <DropdownButton
            variant="outline-secondary border"
            title={IconPickerTitleByType[marker.iconType] ? IconPickerTitleByType[marker.iconType] : 'N/A'}
            id="input-group-dropdown-1"
        >
            {options}
        </DropdownButton>
        {scalePicker}
    </InputGroup>
}

const renderSymbolMarkerScalePicker = (marker: MapPlace) => {
    const iconConfig = marker.iconConfig
    if (iconConfig != null) {
        return <Form.Range 
            value={iconConfig.scaleRangeValue}
            onChange={(evt) => iconConfig.changeScale(evt.target.value)}
        />
    }
}

const renderMarkerConfig = (marker: MapPlace) => {
    switch (marker.iconType) {
        case 'builtin-symbol':
            return renderSymbolMarkerConfig(marker)
        default:
            return <></>
    }
}

const renderSymbolMarkerConfig = (marker: MapPlace) => {
    const iconConfig = marker.iconConfig
    if (iconConfig != null) {
        return <>
            <InputGroup style={{paddingLeft: 0, paddingRight: "0.5rem"}} key="ig-1">
                <InputGroup.Text id="borderColorPicker"
                    style={{paddingLeft: "0.25rem", paddingRight: "0.25rem"}}
                >
                    Line
                </InputGroup.Text>
                <FormControl
                    type="color"
                    value={iconConfig.strokeColor}
                    onChange={(evt) => iconConfig.changeStrokeColor(evt.target.value)}
                />
                <Form.Range 
                    value={iconConfig.strokeWeightRangeValue}
                    onChange={(evt) => iconConfig.changeStrokeWeight(evt.target.value)}
                />
            </InputGroup>
            <InputGroup style={{paddingLeft: 0, paddingRight: "0.5rem"}} key="ig-2">
                <InputGroup.Text id="borderColorPicker"
                    style={{paddingLeft: "0.25rem", paddingRight: "0.25rem"}}
                >
                    Fill
                </InputGroup.Text>
                <FormControl
                    type="color"
                    value={iconConfig.fillColor}
                    title="Choose your color"
                    id="fillColorPicker"
                    onChange={(evt) => iconConfig.changeFillColor(evt.target.value)}
                />
                <Form.Range 
                    value={iconConfig.opacityRangeValue}
                    onChange={(evt) => iconConfig.changeFillOpacity(evt.target.value)}
                />
            </InputGroup>
        </>
    }
}

const MarkerWidget = observer<any, any>(({id}: {id: string}) => {
    const mapStore: MapStore = useContext(MapStoreContext);
    const marker = mapStore.markers.get(id)
    if (marker == null) {
        return null
    }
    return <Accordion.Item eventKey={id}>
        <Accordion.Header>{marker.displayName}</Accordion.Header>
        <Accordion.Body style={{paddingLeft: "0.5rem", paddingRight: "0.5rem"}}>
            <div className="d-flex align-items-top justify-content-between" >
                {renderMarkerType(marker)}
                {renderMarkerConfig(marker)}
                <ButtonGroup>
                    <Button variant="outline-secondary border" 
                        onClick={() => mapStore.toggleMarkerVisible(id)}
                    >
                        <ShowHideIcon isVisible={marker.isVisible}/>
                    </Button>
                    <Button variant="outline-secondary border" 
                        onClick={() => mapStore.removeMarker(id)}
                    >
                        <i className="bi bi-x"></i>
                    </Button>
                </ButtonGroup>
            </div>
        </Accordion.Body>
    </Accordion.Item>
})

export {MarkerWidget}
