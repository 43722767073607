/// <reference path="../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts" />
/*
This is like a dependency container, but...
*/
import { makeAutoObservable, observable, action } from "mobx";
import { createContext } from "react"
import { BingMapSearch } from "../business/searchprovider/bingmapsearch";
import { OsmSearch } from "../business/searchprovider/osmsearch";

class ProviderStore {
    @observable bingmap: Microsoft.Maps.Map|null = null
    @observable bingmapSearch: BingMapSearch|null = null
    @observable osmSeaerch: OsmSearch = new OsmSearch()

    constructor() {
        makeAutoObservable(this)
    }

    @action async initializeBingmapSearch(bingmap: Microsoft.Maps.Map) {
        this.bingmap = bingmap
        const that = this
        Microsoft.Maps.loadModule([
            'Microsoft.Maps.SpatialDataService',
            'Microsoft.Maps.Search'
        ], function () {
            const searchManager = new Microsoft.Maps.Search.SearchManager(bingmap);
            that.bingmapSearch = new BingMapSearch(bingmap, searchManager)
        });
    }
}

export default createContext(new ProviderStore())
export {ProviderStore}
