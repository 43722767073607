import { makeAutoObservable, observable, action, ObservableMap } from "mobx";
import { createContext } from "react"
import { BoundingBox, SearchResult, Path } from "../types/commontypes";
import { PolygonGroup } from "../types/polygon";
import {MapPlace} from "../types/place"

class MapStore {
    @observable mapObj?: google.maps.Map
    @observable polygons: ObservableMap<string, PolygonGroup> = observable.map()
    @observable markers: ObservableMap<string, MapPlace> = observable.map()
    @observable isControlHidden = false

    constructor() {
        makeAutoObservable(this)
    }

    @action setMap(mapObj: google.maps.Map) {
        this.mapObj = mapObj
    }

    @action hideControl() {
        this.isControlHidden = true
    }

    @action showControl() {
        this.isControlHidden = false
    }

    @action addBoundaries(place: SearchResult) {
        if (place.paths != null && place.paths.length > 0) {
            this.addPolygonGroup(place.id, place.displayName, place.paths)
        }
    }

    @action removePolygon(id: string) {
        const polygonGroup = this.polygons.get(id)

        if (polygonGroup != null) {
            polygonGroup.gmapPolygons.forEach(p => p.setMap(null))
            this.polygons.delete(id)
        }
    }

    @action togglePolygonVisible(id: string) {
        const polygonGroup = this.polygons.get(id)
        if (polygonGroup != null) {

            polygonGroup.isVisible = polygonGroup.isVisible ? false : true
            this.polygons.set(id, polygonGroup)
        }
    }

    @action addMarker(place: SearchResult) {
        const id = place.id
        if (!this.markers.has(id)) {
            const newMarker = new MapPlace(id, place.displayName, place.center)
            this.markers.set(id, newMarker)
        }
    }

    @action toggleMarkerVisible(id) {
        const marker = this.markers.get(id)
        if (marker != null) {

            marker.isVisible = marker.isVisible ? false : true
            this.markers.set(id, marker)
        }
    }

    @action removeMarker(id: string) {
        const marker = this.markers.get(id)
        if (marker != null) {
            marker.gmapMarker.setMap(null)
            this.markers.delete(id)
        }
    }

    addPolygonGroup(id: string, displayName: string, pathGroup: Path[]) {
        if (!this.polygons.has(id)) {
            const newPolygon = new PolygonGroup(id, displayName, pathGroup)
            this.polygons.set(id, newPolygon)
        }
    }

    panToBoundingBox(bbox: BoundingBox) {
        const bounds = new google.maps.LatLngBounds(bbox.southWest, bbox.northEast)
        if (this.mapObj != null) {
            this.mapObj.fitBounds(bounds, 10)
        }
    }
}

export default createContext(new MapStore())
export {MapStore}
