import React from 'react';
import './App.css';
import { Col, Container, Row } from 'react-bootstrap';
import { SideBar } from './components/sidebar/sidebar';
import { BingMap } from './components/mapprovider/bmap';
import { GoogleMap } from './components/mapprovider/gmap';
import './injecthacks'

const center = { lat: 47.6101, lng: -122.2015 };
const zoom = 12;
const NO_PADDING_STYLE = {
  paddingLeft: 0,
  paddingRight: 0
}

function App() {
  return <Container fluid style={{paddingLeft: 0}}>
    <SideBar />
    <Row style={NO_PADDING_STYLE}>
      <Col style={{paddingLeft: 0, paddingRight: 0}}>
        <GoogleMap center={center} zoom={zoom}/>
      </Col>
    </Row>
    <BingMap />
  </Container>
}

export default App;
