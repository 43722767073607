import { makeAutoObservable, observable, action } from "mobx";
import { createContext } from "react"
import { searchProviderKeys } from "../business/searchprovider/provider";
import { SearchResult } from "../types/commontypes";

class SearchBoxStore {
    @observable searchInput: string = ''
    @observable results: SearchResult[] = []
    @observable searchProvider: string = searchProviderKeys[0]

    constructor() {
        makeAutoObservable(this)
    }

    @action changeSearchInput(newInput: string) {
        this.searchInput = newInput
    }

    @action changeResults(newResults: SearchResult[]) {
        this.results = newResults
    }

    @action clearResults() {
        this.results = []
    }

    @action changeSearchProvider(newProvider: string) {
        if (searchProviderKeys.includes(newProvider)) {
            this.searchProvider = newProvider
        }
    }
}

export default createContext(new SearchBoxStore())
export {SearchBoxStore}
