import React, { useContext } from "react";
import { observer } from "mobx-react-lite"
import { Col, Row, Offcanvas } from "react-bootstrap";
import { MarkerList } from "../markerlist/markerlist";
import { MarkerListTitle } from "../markerlist/markerlisttitle";
import { PolygonList } from "../polygonlist/polygonlist";
import { PolygonListTitle } from "../polygonlist/polygonlisttitle";
import { SearchBox } from "./searchbox/searchbox";
import { default as SidebarStoreContext, SidebarStore } from '../../stores/sidebarstore';

const SideBar = observer(() => {
    const sidebarStore: SidebarStore = useContext(SidebarStoreContext);

    return <div>
        <Offcanvas show={sidebarStore.isSidebarVisible} 
            onHide={() => sidebarStore.hideSideBar()}
            scroll={true}
            backdrop={false}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Search for places</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Row>
                    <Col><SearchBox /></Col>
                </Row>
                <PolygonListTitle />
                <Row>
                    <Col>
                        <PolygonList />
                    </Col>
                </Row>
                <MarkerListTitle />                  
                <Row>
                    <Col>
                        <MarkerList />
                    </Col>
                </Row>
            </Offcanvas.Body>
        </Offcanvas>
    </div>
})

export {SideBar}
